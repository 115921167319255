import { withStyles, WithStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';

const styles = theme => ({
    container: {
        display: 'inline-block',
        margin: 'auto',
    },
    spinningImg: {
        animationName: 'spin',
        animationDuration: '3s',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'linear',
        display: 'inline-block',
    },
    caption: {
        display: 'block',
    },
    '@keyframes spin': {
        '0%': { transform: 'rotate(0deg)', },
        '100%': {
            transform: 'rotate(360deg)',
        },
    },
});

class Loading extends Component<WithStyles, any> {
    public render() {
        const { classes } = this.props;
        return (
            <div className={classes.container}>
                <img alt="Loading..." className={classes.spinningImg} src="/images/logo_75.png" />
                <p className={classes.caption}>Loading...</p>
            </div>
        );
    }
}

export default withStyles(styles)(Loading);
