export const getRoles = (userId: string, token: string): Promise<string[]> => {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_WEB_API_URL}/api/profiles/roles/${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          return reject(
            `Query Failed: ${response.statusText} - ${response.text}`,
          );
        }
      })
      .then(json => {
        return resolve(json);
      })
      .catch();
  });
};