import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import firebase from "firebase/app";
import * as React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { IPicture } from "../../constants/Interfaces";
import * as routes from "../../constants/routes";
import { AuthUserContext } from "../../contexts/AuthUserContext";
import { getRoles } from "../../firebase/actions";
import { withAuthentication } from "../../firebase/withAuthentication";
import { SignIn } from "../../pages/SignIn";
import asyncComponent from "../AsyncComponent";
import Navigation from "../Navigation";
import { getProfilePicture } from "./actions";

const AsyncAccount = asyncComponent(() => import("../../pages/Account"));
const AsyncApplication = asyncComponent(() => import("../../pages/Application"));
const AsyncArticles = asyncComponent(() => import("../../pages/Articles"));
const AsyncBadges = asyncComponent(() => import("../../pages/Badges"));
const AsyncDiscussions = asyncComponent(() => import("../../pages/Discussion"));
const AsyncFeatureRequests = asyncComponent(() => import("../../pages/FeatureRequests"));
const AsyncHomeComponent = asyncComponent(() => import("../../pages/Home"));
const AsyncLoggingPage = asyncComponent(() => import("../../pages/Logging"));
const AsyncMessaging = asyncComponent(() => import("../../pages/Messaging"));
const AsyncPasswordForget = asyncComponent(() => import("../../pages/PasswordForget"));
const AsyncQuestions = asyncComponent(() => import("../../pages/Questions"));
const AsyncResources = asyncComponent(() => import("../../pages/Resources"));
const AsyncRooms = asyncComponent(() => import("../../pages/Rooms"));
// const AsyncSignIn = asyncComponent(() => import("../../pages/SignIn"));
const AsyncSubscriptionsPage = asyncComponent(() => import("../../pages/Subscriptions"));
const AsyncTicketsPage = asyncComponent(() => import("../../pages/Tickets"));
const AsyncUsers = asyncComponent(() => import("../../pages/Users"));

const NoMatch = () => {
  return (
    <Typography variant="h5" gutterBottom>
      We couldn't find the page you were looking for. If you were following a link, please update your link. Please use
      the menu to find what you were looking for.
    </Typography>
  );
};
const drawerWidth = 240;

const styles = (theme: any) => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  toolbar: theme.mixins.toolbar
});

interface IProps extends WithStyles<typeof styles> {
  container: any;
  theme: any;
}

interface IState {
  mobileOpen: boolean;
  authUser: any;
  profilePicture: IPicture;
  roles: string[];
  userId: string;
  token: string;
  userLoaded: boolean;
}

class AppComponent extends React.Component<IProps, IState> {
  private fireBaseListener: firebase.Unsubscribe | null;
  constructor(props: any) {
    super(props);
    this.fireBaseListener = null;
    this.state = {
      mobileOpen: false,
      authUser: null,
      profilePicture: {} as IPicture,
      roles: [],
      userId: "",
      token: "",
      userLoaded: false
    };
  }

  public componentDidMount() {
    this.fireBaseListener = firebase.auth().onIdTokenChanged((authUser) => {
      if (authUser) {
        authUser
          .getIdToken()
          .then((token) => {
            if (this.state.userLoaded === true) {
              if (this.state.token !== token) {
                this.setState({ token });
              }
            } else {
              Promise.all([getRoles(authUser.uid, token), getProfilePicture(authUser.uid)])
                .then((results) => {
                  this.setState({
                    roles: results[0],
                    userId: authUser.uid,
                    profilePicture: results[1],
                    token,
                    userLoaded: true
                  });
                })
                .catch();
            }
          })
          .catch();
      }
    });
  }

  public componentWillUnmount() {
    this.fireBaseListener && this.fireBaseListener();
  }

  public render() {
    const { classes, theme } = this.props;
    return (
      <AuthUserContext.Provider value={this.state}>
        <BrowserRouter>
          <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
              <Toolbar>
                <div>
                  <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={this.handleDrawerToggle}
                    className={classes.menuButton}>
                    <MenuIcon />
                  </IconButton>
                  <Typography variant="h6" color="inherit" noWrap>
                    PolyAm Date Administration
                  </Typography>
                </div>
                <div style={{ flexGrow: 1 }} />
                <div>
                  <Avatar
                    alt=""
                    src={
                      this.state.profilePicture.mediumUrl && this.state.profilePicture.mediumUrl.length > 0
                        ? this.state.profilePicture.mediumUrl
                        : "/images/blankProfile.png"
                    }
                  />
                </div>
              </Toolbar>
            </AppBar>
            <nav className={classes.drawer}>
              {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
              <Hidden smUp implementation="css">
                <Drawer
                  container={this.props.container}
                  variant="temporary"
                  anchor={theme.direction === "rtl" ? "right" : "left"}
                  open={this.state.mobileOpen}
                  onClose={this.handleDrawerToggle}
                  classes={{
                    paper: classes.drawerPaper
                  }}>
                  <div className={classes.toolbar} />
                  <Navigation onDrawerClose={this.handleDrawerToggle} />
                </Drawer>
              </Hidden>
              <Hidden xsDown implementation="css">
                <Drawer
                  classes={{
                    paper: classes.drawerPaper
                  }}
                  variant="permanent"
                  open>
                  <div className={classes.toolbar} />
                  <Navigation />
                </Drawer>
              </Hidden>
            </nav>
            <main className={classes.content}>
              <div className={classes.toolbar} />
              <Switch>
                <Route exact={true} path={routes.ACCOUNT} component={AsyncAccount} />
                <Route exact={true} path={routes.APPLICATION} component={AsyncApplication} />
                <Route exact={true} path={routes.ARTICLES} component={AsyncArticles} />
                <Route exact={true} path={routes.BADGES} component={AsyncBadges} />
                <Route exact={true} path={routes.DISCUSSIONS} component={AsyncDiscussions} />
                <Route exact={true} path={routes.FEATURE_REQUESTS} component={AsyncFeatureRequests} />
                <Route exact={true} path={routes.SIGN_IN} component={SignIn} />
                <Route exact={true} path={routes.PASSWORD_FORGET} component={AsyncPasswordForget} />
                <Route exact={true} path={routes.HOME} component={AsyncHomeComponent} />
                <Route exact={true} path={routes.LOGGING} component={AsyncLoggingPage} />
                <Route exact={true} path={routes.MESSAGING} component={AsyncMessaging} />
                <Route exact={true} path={routes.USERS} component={AsyncUsers} />
                <Route exact={true} path={routes.QUESTIONS} component={AsyncQuestions} />
                <Route exact={true} path={routes.RESOURCES} component={AsyncResources} />
                <Route exact={true} path={routes.ROOMS} component={AsyncRooms} />
                <Route exact={true} path={routes.SUBSCRIPTIONS} component={AsyncSubscriptionsPage} />
                <Route exact={true} path={routes.TICKETS} component={AsyncTicketsPage} />
                <Route component={NoMatch} />
              </Switch>
            </main>
          </div>
        </BrowserRouter>
      </AuthUserContext.Provider>
    );
  }

  protected handleDrawerToggle = () => {
    this.setState((state) => ({ mobileOpen: !state.mobileOpen }));
  };
}

export const App = withAuthentication(withStyles(styles, { withTheme: true })(AppComponent));
