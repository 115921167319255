import * as firebase from "firebase/app";
import { IPicture, IProfileShort } from "../../constants/Interfaces";

export const getProfilePicture = (userId: string): Promise<IPicture> => {
  return new Promise((resolve, reject) => {
    const ref = firebase.database().ref(`images/${userId}`);
    ref
      .orderByChild('profile')
      .equalTo(true)
      .once('value')
      .then(snapshot => {
        if (snapshot.val() !== null) {
          let picture: any;
          snapshot.forEach(childSnapshot => {
            picture = {
              id: childSnapshot.key ? childSnapshot.key : '',
              description: childSnapshot.val().description,
              profile: childSnapshot.val().profile,
              order: childSnapshot.val().order,
              largeUrl: childSnapshot.val().largeUrl,
              mediumUrl: childSnapshot.val().mediumUrl,
              smallUrl: childSnapshot.val().smallUrl,
            };
          });
          resolve(picture as IPicture);
        } else {
          resolve({} as IPicture);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const getProfileShort = (userId: string, token: string): Promise<IProfileShort> => {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_WEB_API_URL}/api/profiles/short/${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    })
      .then(profileResponse => {
        if (profileResponse.ok) {
          return profileResponse.json();
        }
        return reject(profileResponse.statusText);
      })
      .then(json => {
        return resolve(json as IProfileShort);
      })
      .catch();
  });
};