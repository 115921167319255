export const ACCOUNT = "/account";
export const APPLICATION = "/application";
export const ARTICLES = "/articles";
export const BADGES = "/badges";
export const CREATE_FAKES = "/create_fakes";
export const DISCUSSIONS = "/discussions";
export const FEATURE_REQUESTS = "/feature_requests";
export const HOME = "/";
export const ISSUES = "/issues";
export const LOGGING = "/logging";
export const MESSAGING = "/messaging";
export const PASSWORD_FORGET = "/password_forget";
export const QUESTIONS = "/questions";
export const RESOURCES = "/resources";
export const ROOMS = "/rooms";
export const SIGN_IN = "/signin";
export const SIGN_UP = "/signup";
export const STATISTICS = "/statistics";
export const SUBSCRIPTIONS = "/subscriptions";
export const TICKETS = "/tickets";
export const USERS = "/users";
