import React, { Component } from 'react';
import Loading from '../Loading';

interface IState {
  component: any
};

export default function asyncComponent(importComponent) {
  class AsyncComponent extends Component<any, IState> {
    constructor(props) {
      super(props);
      this.state = {
        component: null
      };
    };
    public async componentDidMount() {
      const { default: component } = await importComponent();

      this.setState({ component });
    }
    public render() {
      const C = this.state.component;

      return C ? <C {...this.props} /> : <Loading />
    }
  }
  return AsyncComponent;
}
