import { SnackbarProvider } from "notistack";
import React from "react";
import ReactDOM from "react-dom";
import { App } from "./components/App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

class PolyAmDate extends React.Component<any, any> {
    public render() {
      return (
        <SnackbarProvider maxSnack={3}>
          <App />
        </SnackbarProvider>
      );
    }
  }
  ReactDOM.render(<PolyAmDate />, document.getElementById('root'));
  

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
