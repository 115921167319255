import * as React from "react";
import { AuthUserContext } from "../contexts/AuthUserContext";
import { firebase } from "../firebase";
import { getRoles } from './actions';

interface IProps {
  authUser?: any;
}

interface IState {
  userId: string;
  roles: string[];
  rolesLoaded: boolean;
  token: string;
}

export const withAuthentication = (Component: any) => {
  class WithAuthentication extends React.Component<
    IProps,
    IState
    > {
    constructor(props: any) {
      super(props);

      this.state = {
        userId: '',
        roles: [],
        rolesLoaded: false,
        token: '',
      };
    }

    public componentDidMount() {
      firebase.auth.onAuthStateChanged(authUser => {
        if (authUser) {
          authUser.getIdToken()
            .then(token => {
              getRoles(authUser.uid, token)
                .then(roles => {
                  this.setState({
                    roles,
                    rolesLoaded: true,
                    userId: authUser.uid,
                    token
                  })
                })
                .catch();

            })
            .catch();
        } else {
          this.setState({ userId: '', roles: [], token: '' })
        }

      });
    }

    public render() {
      return (
        <>
          {/* {this.state.userId.length > 0 && this.state.rolesLoaded && ( */}
          <AuthUserContext.Provider value={this.state}>
            <Component />
          </AuthUserContext.Provider>
          {/* )} */}
        </>
      );
    }
  }
  return WithAuthentication;
};
