import Grid from "@material-ui/core/Grid";
import * as React from "react";
import { Link } from "react-router-dom";
import * as roles from "../../constants/roles";
import * as routes from "../../constants/routes";
import { AuthUserContext } from "../../contexts/AuthUserContext";
import { auth } from "../../firebase";

interface IProps {
  onDrawerClose?: () => void;
}

class Navigation extends React.Component<IProps, any> {
  public render() {
    return (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <>
            {authUser && (
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center">
                <Grid item style={{ marginTop: 5, marginBottom: 5 }}>
                  <Link to={routes.HOME} onClick={this.props.onDrawerClose}>
                    Home
                  </Link>
                </Grid>
                {authUser.roles.includes(roles.ADMIN) && (
                  <>
                    <Grid item style={{ marginTop: 5, marginBottom: 5 }}>
                      <Link
                        to={routes.APPLICATION}
                        onClick={this.props.onDrawerClose}>
                        Application
                      </Link>
                    </Grid>
                    <Grid item style={{ marginTop: 5, marginBottom: 5 }}>
                      <Link
                        to={routes.SUBSCRIPTIONS}
                        onClick={this.props.onDrawerClose}>
                        Subscriptions
                      </Link>
                    </Grid>
                    <Grid item style={{ marginTop: 5, marginBottom: 5 }}>
                      <Link
                        to={routes.MESSAGING}
                        onClick={this.props.onDrawerClose}>
                        Messaging
                      </Link>
                    </Grid>
                    <Grid item style={{ marginTop: 5, marginBottom: 5 }}>
                      <Link
                        to={routes.LOGGING}
                        onClick={this.props.onDrawerClose}>
                        Logs
                      </Link>
                    </Grid>
                    <Grid item style={{ marginTop: 5, marginBottom: 5 }}>
                      <Link
                        to={routes.TICKETS}
                        onClick={this.props.onDrawerClose}>
                        Tickets
                      </Link>
                    </Grid>
                    <Grid item style={{ marginTop: 5, marginBottom: 5 }}>
                      <Link
                        to={routes.FEATURE_REQUESTS}
                        onClick={this.props.onDrawerClose}>
                        Feature Requests
                      </Link>
                    </Grid>
                  </>
                )}
                {authUser.roles.find(
                  (entry) =>
                    entry === roles.ADMIN ||
                    entry === roles.MODERATOR ||
                    entry === roles.STAFF
                ) !== undefined && (
                  <>
                    <Grid item style={{ marginTop: 5, marginBottom: 5 }}>
                      <Link
                        to={routes.USERS}
                        onClick={this.props.onDrawerClose}>
                        Users
                      </Link>
                    </Grid>
                    <Grid item style={{ marginTop: 5, marginBottom: 5 }}>
                      <Link
                        to={routes.ARTICLES}
                        onClick={this.props.onDrawerClose}>
                        Articles
                      </Link>
                    </Grid>
                    <Grid item style={{ marginTop: 5, marginBottom: 5 }}>
                      <Link
                        to={routes.BADGES}
                        onClick={this.props.onDrawerClose}>
                        Badges
                      </Link>
                    </Grid>
                    <Grid item style={{ marginTop: 5, marginBottom: 5 }}>
                      <Link
                        to={routes.QUESTIONS}
                        onClick={this.props.onDrawerClose}>
                        Questions
                      </Link>
                    </Grid>
                    <Grid item style={{ marginTop: 5, marginBottom: 5 }}>
                      <Link
                        to={routes.RESOURCES}
                        onClick={this.props.onDrawerClose}>
                        Resources
                      </Link>
                    </Grid>
                    <Grid item style={{ marginTop: 5, marginBottom: 5 }}>
                      <Link
                        to={routes.ROOMS}
                        onClick={this.props.onDrawerClose}>
                        Rooms
                      </Link>
                    </Grid>
                    {/* <Grid item style={{ marginTop: 5, marginBottom: 5 }}>
                      <Link to={routes.ISSUES} onClick={this.props.onDrawerClose}>Issues</Link>
                    </Grid> */}
                    <Grid item style={{ marginTop: 5, marginBottom: 5 }}>
                      <Link
                        to={routes.DISCUSSIONS}
                        onClick={this.props.onDrawerClose}>
                        Discussion
                      </Link>
                    </Grid>
                  </>
                )}
                <Grid item style={{ marginTop: 5, marginBottom: 5 }}>
                  <button type="button" onClick={this.doSignOut}>
                    Sign Out
                  </button>
                </Grid>
              </Grid>
            )}
            {!authUser && (
              <ul>
                <li>
                  <Link to={routes.SIGN_IN}>Sign In</Link>
                </li>
              </ul>
            )}
          </>
        )}
      </AuthUserContext.Consumer>
    );
  }

  protected doSignOut = () => {
    auth.doSignOut().then().catch();
    this.props.onDrawerClose && this.props.onDrawerClose();
  };
}

export default Navigation;
